import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/01.jpg'
import thumb02 from '../assets/images/thumbs/02.jpg'
import thumb03 from '../assets/images/thumbs/03.jpg'
import thumb04 from '../assets/images/thumbs/04.jpg'
import thumb05 from '../assets/images/thumbs/05.jpg'
import thumb06 from '../assets/images/thumbs/06.jpg'
import thumb07 from '../assets/images/thumbs/07.jpg'
import thumb08 from '../assets/images/thumbs/08.jpg'
import thumb09 from '../assets/images/thumbs/09.jpg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'
import full04 from '../assets/images/fulls/04.jpg'
import full05 from '../assets/images/fulls/05.jpg'
import full06 from '../assets/images/fulls/06.jpg'
import full07 from '../assets/images/fulls/07.jpg'
const add = ' ' + '+' + ' '

const roles = {
  front: 'Front End Development',
  back: 'Back End Developement',
}

const DEFAULT_IMAGES = [
  
  
  {
    id: '23544345665666363463',
    src: full02,
    thumbnail: full02,
    projectDetails: {
      role: 'Software Development',
      stack: 'React Native + Elixir + AWS',
    },
    caption: 'Mill',
    description:
      'Broadcasting & Media Production Company. Blvd specializes in after effects and production design',
    link: 'https://mill.com',
  },

  {
    id: '23544345665666363463',
    src: full02,
    thumbnail: full02,
    projectDetails: {
      role: roles.front,
      stack: 'Next JS + Headless Shopify API',
    },
    caption: 'Staff',
    description:
      'Broadcasting & Media Production Company. Blvd specializes in after effects and production design',
    link: 'https://meetyourstaff.co',
  },
  {
    id: '23544345665666363463',
    src: full02,
    thumbnail: full02,
    projectDetails: {
      role: roles.front,
      stack: 'Gatsby and Prismic',
    },
    caption: 'R&D - New York Times',
    description:
      'Broadcasting & Media Production Company. Blvd specializes in after effects and production design',
    link: 'https://rd.nytimes.com/',
  },
  {
    id: '2354363463',
    src: full02,
    thumbnail: full02,
    projectDetails: {
      role: roles.front,
      stack: 'Gatsby, WP Headless and Serverless',
    },
    caption: 'Fortis',
    description:
      'Broadcasting & Media Production Company. Blvd specializes in after effects and production design',
    link: 'https://www.fortis.com.au',
  },

  {
    id: '23544345665666363463',
    src: full02,
    thumbnail: full02,
    projectDetails: {
      role: roles.front + add + 'Design - GrandArmy',
      stack: 'Gatsby, Sanity, Mapbox.io  and Serverlees',
    },
    caption: 'Corsair Capital ',
    description:
      'Broadcasting & Media Production Company. Blvd specializes in after effects and production design',
    link: 'https://corsair-capital.com/portfolio-infrastructure',
  },

  {
    id: 'fafLLLSdadascdv2',
    src: full07,
    thumbnail: full07,
    caption: 'sunroom',
    projectDetails: {
      role: roles.front + add + 'Design - World Creation Studios',
      stack: 'Vanilla, Sanity  and Serverless',
    },
    description:
      'Broadcasting & Media Production Company. Blvd specializes in after effects and production design',
    link: 'https://sunroom.is//',
  },

  {
    id: 'fafLLLSdadaddddscdv2',
    src: full07,
    thumbnail: full07,
    caption: 'Menad Kesraoui',
    projectDetails: {
      role: roles.front + add + ' Design - 75-20',
      stack: 'React, Sanity and Serverless ',
    },
    description:
      'Broadcasting & Media Production Company. Blvd specializes in after effects and production design',
    link: 'https://menadkesraoui.com/',
  },
  {
    id: '2354363463',
    src: full02,
    thumbnail: full02,
    projectDetails: {
      role: roles.front,
      stack: 'Gatsby, Shopify API, Sanity and Serverless',
    },
    caption: 'Wellware',
    description:
      'Broadcasting & Media Production Company. Blvd specializes in after effects and production design',
    link: 'https://mywellware.com',
  },
  {
    id: '5',
    src: full06,
    thumbnail: thumb06,
    projectDetails: {
      role: roles.front + ' + ' + roles.back,
      stack:
        'Maintainer: React, Redux , Mongo db + Mongoose , Express , Aws Cognito',
    },
    caption: 'N.10as ',
    description:
      'Broadcasting & Media Production Company. Blvd specializes in after effects and production design',
    link: 'http://www.n10.as/',
  },

  // {
  //   id: '2',
  //   src: full07,
  //   thumbnail: full07,
  //   caption: 'BLVD',
  //   projectDetails: {
  //     role: roles.front,
  //     stack: 'React, Appolo and Prismic',
  //   },
  //   description:
  //     'Broadcasting & Media Production Company. Blvd specializes in after effects and production design',
  //   link: 'https://blvd-mtl.com/',
  // },

  {
    id: '5',
    src: thumb05,
    thumbnail: thumb03,
    projectDetails: {
      role: roles.front,
      stack: 'Gatsby , Tumblr Api and Contentful',
    },
    caption: 'Little baby Angel',
    description: 'Montreal based Duo, signed to Lucky.Me',
    link: 'https://littlebabyangel.com/',
  },

  // {
  //   id: '1',
  //   src: full01,
  //   thumbnail: thumb01,
  //   caption: 'Michel de Broin',
  //   projectDetails: {
  //     role: roles.front,
  //     stack: 'Wordpress',
  //   },
  //   description:
  //     'Michel de Broin is a Canadian sculptor. De Broin has created numerous public artworks in Canada and Europe, including the Salvador Allende monument in Montreal. He was the recipient of the 2007 Sobey Art Award.',
  //   link: 'https://micheldebroin.org/en/',
  // },
]

class HomeIndex extends React.Component {
  constructor() {
    super()

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
    }

    this.closeLightbox = this.closeLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.handleClickImage = this.handleClickImage.bind(this)
  }

  openLightbox(index, event) {
    event.preventDefault()
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    })
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }
  handleClickImage() {
    if (this.state.currentImage === this.props.images.length - 1) return

    this.gotoNext()
  }

  render() {
    const siteTitle = 'Jamal Jean'
    const siteDescription = 'Full Stack Developer - Jamal Jean'

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main">
          <section id="one">
            <header className="major"></header>
            {/* <ul>
              <li>
                <span>Gatsby / Next Js</span>
              </li>
              <li>
                <span>React / React Native</span>
              </li>
              <li>
                <span>Redux / Flux</span>
              </li>
              <li>
                <span>Wordpress / Drupal </span>
              </li>
              <li>
                <span> Headless CMS : Prismic / Sanity.io / Contentful</span>
              </li>
              <li>
                <span> Ecommerce Shopify API</span>
              </li>
              <li>
                <span>Node JS / Express / KOA</span>
              </li>
              <li>
                <span>Appolo Client / Graph QL </span>
              </li>
              <li>
                <span>Mocha / Jest</span>
              </li>
              <li>
                <span>AWS: Cognito / Lambda / S3 Bucket / Amplify </span>
              </li>

              <li>
                <span>
                  Wide range of API experience: Facebook, Twitter, Twilio,
                  Mindbody, Hubspot
                </span> */}
            {/* </li>
            </ul> */}
          </section>

          <section id="two">
            {/* <h2> Works</h2> */}

            <Gallery
              images={DEFAULT_IMAGES.map(
                ({
                  id,
                  src,
                  thumbnail,
                  caption,
                  description,
                  link,
                  projectDetails,
                }) => ({
                  src,
                  thumbnail,
                  caption,
                  description,
                  projectDetails,
                  link,
                })
              )}
            />
          </section>
          {/* <section id="three">
            <div>
              <a href="mailto:jamal.tobias@gmail.com">CONTACT</a>
            </div>
          </section> */}
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
