import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

class Header extends React.Component {
  render() {
    return (
      <header id="header">
        <div className="inner">
          <a href="#" className="image avatar">
            <img
              src="https://lh3.googleusercontent.com/a/AEdFTp4_Kqg6J7Z7eLjAbTG92qSAIjko5azSBqpM4yKDFw=s192-c-mo"
              alt=""
            />
          </a>
          <h1>Jamal Jean - Full Stack Developer</h1>
        </div>
        <Footer />
      </header>
    )
  }
}

export default Header
