import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <div id="footer">
                <div className="inner">
                    <ul className="icons">
                        <li><a href="https://twitter.com/jamaljean" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
                        <li><a href="https://github.com/jamaljeantobias" className="icon fa-github"><span className="label">Github</span></a></li>
                        <li><a href="https://www.linkedin.com/in/jamal-jean-tobias-b4199115b/" className="icon fa-linkedin"><span className="label">Dribbble</span></a></li>
                        <li><a href="mailto:jamal.tobias@gmail.com" className="icon fa-envelope-o"><span className="label">Email</span></a></li>
                    </ul>
                    <ul className="copyright">
                        <li> </li><li> <a href="http://html5up.net"></a></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Footer
